import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/da/analyse-konsulent",
  "Data Layer": "/da/data-lag"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

const DataLayer = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Data Layer forklaret med Tutorial [2020]"
        description="Et data layer er det grundlæggende koncept for enhver professionel webanalyseopsætning. Men hvad er det egentlig, og hvordan implementerer vi det?"
        lang="da"
        image="data-layer-code-hero-image3"
        alternateLangs={alternateLangs}
        canonical="/da/data-lag"
        datePublished="2024-06-11T04:32:43.188Z"
        dateModified="2024-06-11T15:21:48.881Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="data-layer/data-layer-code-hero-image3.png"
          alt="Visualisering af Google Tag Manager Data Layer i browserens konsol"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Data Layer</H>
        <p>
          I forbindelse med tag management og webanalyse, har du måske hørt udtrykket <b>data layer</b>. Det er det
          grundlæggende element i en ambitiøs webanalyseopsætning, fordi alle datapunkter og sporingsregler afhænger af
          det.
        </p>
        <p>
          Derfor behandles det i digital analyse som et ufravigeligt krav for enhver analyseopsætning. Dog findes der også
          scenarier, hvor det ikke er nødvendigt.
        </p>
        <p>
          Derfor vil jeg forklare <Link to="/da/data-lag">hvad et data layer er</Link>,{" "}
          dets <Link to="/da/data-lag">fordele</Link>, og forskellene mellem{" "}
          <Link to="/da/data-lag">data layer for Google Tag Manager</Link> og{" "}
          <Link to="/da/data-lag">Adobe Launch</Link>.
        </p>
        <p>
          Derefter vil vi se på implementeringen for de mest populære Tag Management Systems (TMS). Jeg vil forklare{" "}
          <Link to="/da/data-lag">design</Link> fasen, efterfulgt af{" "}
          <Link to="/da/data-lag">implementering</Link> og <Link to="/da/data-lag">debugging</Link>.{" "}
        </p>
        <H as="h2">Hvad er et data layer?</H>
        <p>Et Data Layer er en <b>datastruktur</b>, der giver relevant information i Key-Value Pairs til brug med for eksempel <Link to="/da/tag-styring">Tag Management Systems</Link>.</p>
        <p>Et Data Layer er tilgængeligt i den globale JavaScript-scope på hjemmesiden som en <b>Array</b> eller <b>Object</b> og holder data i en struktureret form til brug for andre programmer.</p>
        <p>Fordelen ved et Data Layer ligger i en programmæssigt simpel adgang til relevant data under et besøg på en hjemmeside.</p>

        <p>
          Det muliggør adgang til data på et centralt punkt og er grundlaget for dataanalyse-logikken i et <Link to="/da/tag-styring">tag management system</Link>.
        </p>
        <SnippetJS caption="Kodeeksempel til oprettelse af datalayer for Google Tag Manager. Det opretter et JavaScript-Objekt i et array (!) der holder alle relevante dimensioner til senere dataanalyse og regeloprettelse i tag management systemet.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];`}
        </SnippetJS>
        <p>
          Da det bruges til at gemme data fra flere datakilder, forenkler det overvågningen af aktuelle dataværdier,
          fordi kun ét sted skal observeres ("single point of truth").
        </p>
        <p>
          Et data layer bliver <b>genopbygget ved hver sideload</b> udstyret med datapunkter på den aktuelle webside og
          muligvis andre relaterede data om besøgende og deres besøg.
        </p>
        <p>
          <b>Bemærk:</b> Single page applications (SPA) genindlæser ikke siden mellem navigering. Derfor er
          datalayer-konfigurationen for en single page application anderledes end for typiske websider med sideloads.
        </p>
        <p>
          De holdte data repræsenterer egenskaber eller funktioner af en underside og holdes i en key-value-pair. Nøglerne
          holder beskrivende navne på funktionerne parret med en aktuel værdi, som typisk ændres under brugerens rejse.
        </p>
        <SnippetJS caption="De relevante funktioner af en underside, såsom dens kategori, navn og sprog, gemmes i variabler og kan senere bruges til analyse eller regeloprettelse i TMS.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page", //category
  "pageName": "sneaker overview", //name
  "language": "en-US", //language
}];`}
        </SnippetJS>
        <p>
          Det overordnede mål er at gøre disse datapunkter tilgængelige i tag management systemet, så de kan sendes
          sammen med dataen delt med f.eks. Google Analytics eller Facebook Ads for bedre at beskrive
          hjemmesideinteraktioner.
        </p>
        <p>
          For at muliggøre denne integration holder TMS referencer til key-value-pairs og kan for eksempel udføre
          regler, når deres værdi ændres.
        </p>
        <p>
          <b>Eksempel:</b> En besøgende zoomer ind på et produktbillede og udløser dermed en begivenhed "produktzoom". Uden
          yderligere data sendt sammen med begivenheden er det ikke meget indsigtsfuldt. Derfor sender vi også data om
          f.eks. produktnavn, kategori og pris, så vi kan analysere begivenheden i en meningsfuld kontekst.
        </p>
        <p>
          De yderligere data gør det muligt at verificere, om sådanne produktzooms kun sker i bestemte produktkategorier. Hvis
          ja, kan det være gavnligt at tilføje flere billeder til andre produkter i samme kategori, da besøgende synes at være meget interesseret i billeddetaljerne af disse produkter.
        </p>
        <p>
          Kort sagt strukturerer vi alle relevante data i beskrivende nøgler og værdier og gør dem tilgængelige på et
          centralt sted, hvor de nemt kan hentes.
        </p>
        <p>
          Sådanne datapunkter er normalt vigtige egenskaber ved sideindholdet eller en klassifikation, vi har oprettet for at segmentere besøgende baseret på adfærd.
        </p>
        <p>
          For bedre at forstå, hvad et data layer er, som en forenklet visualisering, kan du forestille dig et Excel-ark.
          Arkene holder vigtige egenskaber om en webside i sin header (sti, sprog, kategori,
          logget-ind-status) sammen med en aktuel værdi for hver post.
        </p>
        <ImgContainerFixed width="449px">
          <ImgScreenshot
            src="data-layer/data-layer-structure-excel-example.png"
            alt="Forenklet data layer eksempel i Excel"
            className="article-img"
            caption="Du kan forestille dig et data layer som en simpel tabel med kolonneoverskrifter "
          />
        </ImgContainerFixed>
        <H as="h2">Hvad er fordelene ved at bruge et data layer?</H>
        <p>
          Mens en besøgende navigerer gennem en hjemmeside, sker der mange brugerinteraktioner: klik på knapper, udfyldning af formularer eller afspilning af videoer.
        </p>
        <p>
          Hvis disse interaktioner giver os mulighed for at drage konklusioner om brugerengagement, sendes de f.eks. til Google
          Analytics sammen med andre beskrivende data om den besøgende, sessionen, begivenheden selv eller det
          HTML-element, de interagerede med.
        </p>
        <p>
          <u>Og dette er det afgørende punkt</u>: De ekstra data til at beskrive sådanne interaktioner kommer fra forskellige
          datakilder, for eksempel fra <b>frontend, database</b> eller en ekstern <b>API</b>.
        </p>
        <p>
          For at forstå fordelene ved et data layer, skal vi først forstå udfordringerne, der opstår, når vi har brug for data fra flere datakilder.
        </p>
        <p>Lad os se på et eksempel, hvor vi indsamler data fra sådanne kilder, og lad os tænke det igennem:</p>
        <p>
          <b>Eksempel:</b> Den besøgende køber et produkt på en hjemmeside. Følgende dimensioner kunne være interessante:
        </p>
        <ul
        // TODO: vis listen i 2 kolonner, problem: ser godt ud på desktop, men ikke på mobil
        // style={{ columns: "2", "-webkit-columns": "2", "-moz-columns": "2" }}
        >
          <li>produktnavn</li>
          <li>produktpris</li>
          <li>produktstørrelse</li>
          <li>produktkategori</li>
          <li>produktfarve</li>
          <li>indkøbskurvværdi</li>
          <li>mærke</li>
          <li>første køb</li>
          <li>kundesegment</li>
          <li>kundens rabat</li>
          <li>køn</li>
          <li>land</li>
        </ul>
        <p>
          Efter købet lander de besøgende på en takkeside, der viser alle detaljer om købet og leveringsadressen.
        </p>
        <p>
          <b>Frontend</b>: For at sende produktdata, indkøbskurvværdi og mærke sammen med begivenheden, kan vi
          potentielt skrabe det fra takkesiden.
        </p>
        <p>
          Den største udfordring ved at skrabe data fra en webside er, at dataene skal være tilgængelige på hver side, hvor
          interaktionen finder sted. Dette er sjældent tilfældet i virkeligheden.
        </p>
        <p>
          Det er tilrådeligt at måle så mange af de samme dimensioner på tværs af alle interaktioner på en hjemmeside for at gøre
          interaktionerne sammenlignelige senere under dataanalyse. Derfor, hvis vi skulle følge denne tilgang, er det sandsynligt
          at andre sider ikke viser <i>produktdata, indkøbskurvværdi og mærke</i> for at sende sammen med andre begivenheder.
        </p>
        <p>
          Så hvis de nødvendige data ikke er tilgængelige på andre sider, vil vi undgå at tilføje alle de data til indholdet
          bare for analysens skyld. <b>Det er derfor, vi bruger et data layer</b>. Det gør dataene tilgængelige for os at
          indsamle, uanset om de er synlige på siden eller ej. Det er bogstaveligt talt et lag af data, der ligger oven på en
          given underside, og leverer de data, vi har brug for.
        </p>
        <p>
          En anden udfordring ved at skrabe data fra frontend er, at det til sidst går i stykker. Når sider ændres
          og disse ændringer påvirker HTML-strukturen af de skrabede elementer, vil dataindsamlingen gå i stykker.
          Især i større virksomheder er der hyppige ændringer på sider og flere teams, der arbejder på dem
          uden at vide, om nogle HTML-elementer er nødvendige for dataindsamling. Derfor vil enhver skrabning af data fra frontend på et tidspunkt gå i stykker på hyppigt opdaterede hjemmesider.
        </p>
        <p>
          Et data layer udnytter denne tilgang og gør det muligt at hente brugerdata på en sikker og enkel
          måde.
        </p>
        <p>
          <b>Database</b>: Indsamling af kundedata (kundesegment, rabat, køn og første køb) kan blive lidt tricky: Kundedata skal enten sendes sammen med serverens svar eller med en
          separat API.
        </p>
        <p>
          Men da dette er private data, skal indsamlingen være godkendt af hensyn til databeskyttelse. Det
          betyder, at en API-anmodning ikke kunne håndteres i browseren, fordi API-nøglen ellers ville kunne hentes
          af erfarne brugere.
        </p>
        <p>
          Derfor er den bedste løsning at sende dataene sammen med serverens svar baseret på hjemmesidens
          login-autentificering. <br />
          Når brugeren er logget ind, bliver data layer udfyldt med de relevante data fra databasen. Uden
          login, bliver ingen følsomme data eksponeret.
        </p>
        <p>
          <b>API</b>: Geo data såsom landet kunne hentes fra en ekstern service API.
        </p>
        <p>
          Dog opstår den samme udfordring som ved hentning af data fra databasen: Enhver API-anmodning fra
          frontend eller tag management systemet kræver en API-nøgle, som ikke bør håndteres i browseren af
          sikkerhedsmæssige årsager.
        </p>
        <p>
          En anden ulempe ved at arbejde med API'er til dataindsamling, især med hændelser, er varigheden indtil
          dataene kommer tilbage. Hvis en bruger navigerer til en anden side, før dataene er ankommet, risikerer vi at miste
          hændelsen.
        </p>
        <p>Lad os hurtigt opsummere fordelene:</p>
        <H as="h3">Fordele</H>
        <ul>
          <li>Data er tilgængelig, uanset om den er synlig på siden</li>
          <li>Robust dataindsamling</li>
          <li>Sikker indsamling af følsomme data</li>
          <li>Reducerer datatab ved asynkrone dataanmodninger</li>
        </ul>
        <H as="h2">Hvorfor du sandsynligvis har brug for et</H>
        <p>
          Ved at oprette et data layer bliver et JavaScript-objekt tilgængeligt i den globale scope i browseren ved hver
          sideload.
        </p>
        <p>
          Dataene, det indeholder, kan stamme fra din database, frontend eller API'er, så dataindsamling fra disse kilder
          bliver pålidelig, sikker og uafhængig af HTML på siden.
        </p>
        <p>
          Data fra databasen kan gøres tilgængelig på enhver underside af hjemmesiden uden meget besvær uden at være
          synlig i indholdet.
        </p>
        <p>
          Af ovenstående grunde råder jeg generelt klienter til at implementere data layers, hvis de er seriøse omkring deres
          dataanalyseambitioner. Fordelene ved datakvalitet, pålidelighed og de tilknyttede langsigtede tidsbesparelser
          retfærdiggør de højere implementeringsomkostninger.
        </p>
        <p>
          Det ultimative mål med webanalyse er at træffe databaserede forretningsbeslutninger, så datakvalitet bør være en
          prioritet.
        </p>
        <p>
          Lad os nu se på de forskellige tilgængelige muligheder og nogle implementeringseksempler, før vi dykker ned i
          design- og implementeringsfasen.
        </p>
        <H as="h2">Data Layer for Adobe Analytics, DTM, Launch og Tealium</H>
        <p>
          Data Layers kan have forskellige strukturer. Generelt skelner vi mellem dem, der kommer med en{" "}
          <b>objekt-baseret struktur</b> og en <b>array-baseret struktur</b>.
        </p>
        <p>
          Ifølge{" "}
          <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
            data layer definition
          </a>{" "}
          fra World Wide Web Consortium (W3C) følger syntaksen den for et JavaScript-objekt. Det er uofficielt
          forkortet <b>CEDDL</b> (Customer Experience Digital Data Layer).
        </p>
        <p>Du kan også indlejre andre objekter eller arrays i det:</p>
        <SnippetJS caption="Eksempel på et data layer for Adobe Analytics med en objekt-baseret struktur, der følger W3C-definitionen.">
          {`
window.digitalData = {
  pageName: "sneaker oversigt",
  destinationPath: "/da/sneakers",
  breadCrumbs: ["hjem","sneakers"],
  publishDate: "2020-07-01",
  language: "en-US"
};`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics, Adobe Launch</b> og Tealium følger CEDDL-strukturen. I eksemplet ovenfor gemmer vi
          data i et objekt kaldet <code>digitalData</code>. Navnet er ikke standardiseret og kan vælges frit,
          men du skal erklære navnet i tag management systemet.
        </p>
        <p>
          For at ændre dataene er der flere muligheder (som med ethvert JS-objekt), men den enkleste måde er bare
          at overskrive værdierne:
        </p>
        <SnippetJS caption="For at tilføje data til data layer, overskriv blot eksisterende data som med ethvert almindeligt JavaScript-objekt.">
          {`window.digitalData.language = "da-DK";`}
        </SnippetJS>
        <p>
          Hovedideen med den objekt-baserede struktur er, at de kun indlæses pr. sideload, men de ændres ikke
          meget baseret på brugerinteraktion. Dataene er for det meste <b>statiske</b>.
        </p>
        <p>
          Hændelsessporing er ikke drevet af hændelser, der indgår i data layer-objektet. Hændelser spores med en{" "}
          <b>separat sporingsbibliotek</b> til at sende dem videre til en analyseplatform f.eks. Adobe Analytics. Når
          hændelsessporingskoden udføres, sendes data layer-objektet med i sin helhed og kan bruges
          under dataanalyse.
        </p>
        <SnippetJS caption="Hændelsessporing for Adobe med en objekt-baseret data layer-struktur håndteres gennem _satellite biblioteket.">
          {`
//Event med valgt farve
_satellite.setVar("sneaker farve", "sort");
_satellite.track("vælg farve"); 

`}
        </SnippetJS>
        <H as="h2">Brug Adobe Launch med et array-baseret data layer</H>
        <p>
          Du kan nemt bruge Adobe Launch med en array-baseret struktur. Adobe Launch Extension{" "}
          <b>Data Layer Manager</b> gør det muligt.
        </p>
        <p>Her er nogle links til yderligere ressourcer til brug af den array-baserede version med Adobe Launch:</p>
        <ul>
          <li>
            Jim Gordon’s{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Demo af brug af Data Layer Manager med Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extension med{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              dokumentation
            </a>
          </li>
        </ul>
        <H as="h2">Data Layer for Google Tag Manager, Matomo og Piwik Pro</H>
        <p>
          <b>Data layer for Google Tag Manager</b>, Matomo og Piwik Pro er array-baseret og uofficielt omtalt som
          event-driven data layer <b>(EDDL)</b>.
        </p>
        <p>
          Data håndteres også i objekter, men den overordnede struktur af GTM data layer er en <b>array med objekter</b>.
        </p>
        <SnippetJS caption="Kodeeksempel for tilføjelse af et GTM data layer">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];
`}
        </SnippetJS>
        <p>
          Tracking-logikken med en array-baseret struktur er anderledes: Nye data eller ændringer skubbes ind i den via{" "}
          <code>dataLayer.push()</code>. Så en <b>push til data layer</b> kan udløse tag-eksekveringer i tag management systemet.
        </p>
        <p>
          Den grundlæggende forskel fra en objekt-baseret struktur er, at ændringer normalt sendes sammen med en
          hændelse og at regler udløses baseret på disse ændringer uden noget ekstra bibliotek, bare ved at observere
          om data layer-array ændres.
          <br />
          Da intet andet bibliotek som <code>_satellite</code> er nødvendigt, kræver vi en afhængighed mindre.
        </p>
        <p>
          En anden egenskab ved den array-baserede tilgang er, at data ændres ret ofte i løbet af
          brugerrejsen, da enhver brugerinteraktion kan ændre data layer-variablerne.
        </p>{" "}
        <p>
          Så en array-baseret data layer er grundlaget for event tracking og håndterer data mere fleksibelt, mens en
          objekt-baseret snarere tjener som et statisk datalager.
        </p>
        <p>
          Gennem denne fleksibilitet siges en array-baseret data layer-struktur at være mere velegnet til
          Single-Page-Applications. <br />
          Du kan dog også spore SPAs med en objekt-baseret data layer, det vil bare kræve nogle flere linjer kode og potentielt nogle få kanttilfælde at løse.
        </p>
        <p>
          Hvis du er i begyndelsen af et projekt og har valget, vil jeg foretrække en array-baseret data layer.
          <br />
          At ændre en allerede eksisterende opsætning fra en objektstruktur til en array er dog unødvendigt.
        </p>
        <H as="h2">Content Management Systems med data layer inkluderet</H>
        <p>
          <b>WordPress</b>-brugere har det nemt, da de kan bruge{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
            dette plugin
          </a>{" "}
          til at implementere Google Tag Manager sammen med et forudkonfigureret data layer.
        </p>
        <p>Det fyldes automatisk med kategorier, forfatternavne, udgivelsesdatoer og søgeord.</p>
        <p>
          Datapunkterne kan tjekkes eller fjernes i plugin-indstillingerne. Desuden tilbyder plugin'et
          forudkonfigurerede hændelser for formularindsendelser fra de mest almindelige formularplugins.
        </p>
        <p>
          Hvis du er webshop-ejer og bruger <b>WooCommerce</b> til WordPress, kan du implementere et{" "}
          <b>klassisk e-handels data layer</b> samt et <b>forbedret e-handels data layer</b> med det samme
          plugin, hvilket er ret kraftfuldt.
        </p>
        <p>
          <b>WordPress</b>-brugere, der vil bruge <b>Tealium</b>, kan bruge et plugin til{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
            Tealium
          </a>
          .
        </p>
        <p>
          <b>Drupal</b> har også et{" "}
          <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
            plugin
          </a>
          .
        </p>
        <p>
          <b>Wix</b> og <b>Squarespace</b>-brugere kan implementere Google Tag Manager gennem platformens værktøjer, men skal
          implementere data layer-koden manuelt.
        </p>
        <H as="h2">Data layer implementering</H>
        <p>
          Så hvordan implementerer du et data layer? - Da planlægning og implementering kræver viden på tværs af områderne
          digital analyse, frontend udvikling og backend udvikling, udføres implementeringen normalt af et webbureau sammen med en{" "}
          <Link to="/da/google-analytics-konsulent">analytics konsulent</Link>.
        </p>
        <p>
          Analytics konsulenten briefer webbureauet og leder projektet, indtil implementeringen er
          succesfuldt valideret. Derefter konfigureres tag management systemet og analyseværktøjerne.
        </p>
        <p>
          Hvis du er interesseret og kender lidt til JavaScript, kan du implementere det selv med følgende
          <b>implementeringsguide</b>.
        </p>
        <p>Implementeringen går gennem 3 trin:</p>
        <ol>
          <li>
            <Link to="/da/data-lag">Data Layer Design</Link>
          </li>

          <li>
            <Link to="/da/data-lag">Implementering</Link>
          </li>

          <li>
            <Link to="/da/data-lag">Fejlfinding</Link>
          </li>
        </ol>
        <H as="h3">1. Data Layer Design</H>
        <p>
          Designfasen handler om at definere, hvilke interaktioner der skal måles sammen med hvilke dimensioner.
        </p>
        <p>
          Enhver attribut af den besøgende, sessionen, siden, produktet eller hændelsen kan være af potentiel interesse under dataanalyse og bør overvejes til data layer-arkitekturen.
        </p>
        <p>
          For at beslutte hvad der skal inkluderes, start med slutmålet i tankerne og spørg dig selv, hvilke forretningskritiske spørgsmål der skal besvares, og indsnævr de relaterede datapunkter.
        </p>
        <p>
          Næste skridt er at finde ud af, hvordan disse datapunkter skal struktureres, og hvilke dimensioner der er de vigtigste at tilføje.
        </p>
        <p>
          <b>Eksempel</b>: En sprogskole, der driver en WordPress-hjemmeside på flere sprog, vil gerne vide de besøgendes modersmål og hvilket fremmedsprog, de er mest interesserede i. Målet er potentielt at køre online annoncering via Facebook Ads målrettet demografiske grupper med lignende attributter.
        </p>
        <p>
          Som næste skridt skal vi definere alle relevante data på tværs af forskellige typer sider (hjemmeside, kurssider, om os, kontakt og nyheder). For at forenkle, lad os se på de mest interessante sider og fokusere på forsiden og kurssider.
        </p>
        <ImgContainerFixed width="400px">
          <ImgScreenshot
            src="data-layer/data-layer-example-language-school.png"
            alt="Data Layer design eksempel for en sprogskole hjemmeside"
            caption="Eksempel på et data layer design for en sprogskoles forside og kurssider"
          />
        </ImgContainerFixed>
        <p>
          <b>Eksempel</b>: Array-baseret Google Tag Manager data layer for en sprogskole
        </p>
        <SnippetJS caption="Kodeeksempel for at oprette et GTM data layer for en sprogskole. Bemærk, at tal er erklæret som strenge.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": "da", //Sprog for UI
  "sessionDuration": "182", //Sessionsvarighed i sek
  "languageIntent": "es", //mest besøgte kurssprog
  "pageType": "kursside",
  "courseName": "Spansk A1 - Begynder",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Varighed i uger
}];
`}
        </SnippetJS>
        <p>
          <b>Eksempel</b>: Objekt-baseret data layer for Adobe Launch
        </p>
        <SnippetJS caption="Initialisering af et data layer for Adobe Launch eller Adobe DTM.">
          {`
window.digitalData = window.digitalData || {
  "language": "da", //Sprog for UI
  "sessionDuration": 182, //Sessionsvarighed i sek
  "languageIntent": "es", //mest besøgte kurssprog
  "pageType": "kursside",
  "courseName": "Spansk A1 - Begynder",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Varighed i uger
};
`}
        </SnippetJS>
        <H as="h3">2. Implementering</H>
        <p>
          Data layers skal implementeres på hver underside af en hjemmeside. De ovenstående kodeeksempler viser dog den
          endelige <b>beregnede tilstand</b>.
        </p>
        <p>
          Under implementeringen skal datapunkterne først hentes for at blive beregnet til deres endelige tilstand, så
          den faktiske kilde kommer til at se lidt anderledes ud.
        </p>
        <p>For at give et realistisk eksempel antager jeg følgende:</p>
        <ul>
          <li>
            Sessionsvarighed og sproginteresse indsamles gennem et speciallavet JavaScript og holdes i
            browserens lokale lagring.
          </li>
          <li>
            Sprog, sidetype og kursusdata kan hentes fra databasen via serverens svar og gøres
            tilgængelige på kursskabeloner og forsiden.
          </li>
        </ul>
        <p>Kildekoden for data layer i backend ifølge ovenstående forudsætninger vil se sådan ud:</p>
        <SnippetJS caption="Eksempel på kildekode for et data layer før beregning.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent,
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseLang": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
        </SnippetJS>
        <H as="h4">Event tracking med data layer push</H>
        <p>For at sende events til et GTM data layer kan du bruge dets push-metode og bogstaveligt talt skubbe events ind i det.</p>
        <SnippetJS caption="Data layer push-eksempel for event tracking">
          {`
window.dataLayer.push({
  "event": "course-booking", 
  "startWeek": "24"
});
`}
        </SnippetJS>
        <p>
          <b>Event</b>-nøglen er en speciel nøgle og kan adresseres som en <b>custom event</b> fra GTM
          containeren.
        </p>
        <p>
          Tag management systemet observerer data layer og eksekverer et tag, så snart en foruddefineret custom event er
          sendt til det.
        </p>
        <p>Efter en event er tilføjet, kan TMS for eksempel sende en event til Google Analytics.</p>
        <p>
          Al relevant data for at give kontekst (navn, sprog, sprogniveau, kursusvarighed) er tilgængelig og kan
          sendes sammen med eventen, for eksempel startugen for kurset.
        </p>
        <p>
          I et objekt-baseret data layer vil den samme event blive sendt direkte til Adobe Analytics via deres eget event tracking-bibliotek.
        </p>
        <p>For Adobe Launch vil eksempel koden se sådan ud:</p>
        <SnippetJS caption="Adobe Launch-eksempel for event tracking. Bemærk, at dataen sendes direkte til Adobe Analytics uden først at gå gennem data layer.">
          {`
_satellite.setVar("startWeek", 24);
_satellite.track("course-booking");
`}
        </SnippetJS>
        <H as="h4">Kodens placering i kildekoden</H>
        <p>
          Data layer-koden skal tilføjes i <code>{`<head>`}</code> på siden før tag management systemet.
        </p>
        <p>
          På grund af denne rækkefølge sikrer du, at den allerede er beregnet, når tag management systemet vil få adgang til den.
        </p>
        <p>
          <b>Eksempel</b>: Placering i kildekoden
        </p>
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="placering af data layer før TMS tag i kildekoden"
          caption="Data layer skal placeres i <head>-sektionen før tag management systemet for at sikre, at dataen allerede er indlæst, når TMS vil få adgang til den."
        />
        <H as="h3">3. Fejlfinding</H>
        <p>
          De mest almindelige procedurer til at fejlfinde et data layer er at simulere sideloads eller hændelser for at verificere, at alle datapunkter udfyldes med de korrekte data.
        </p>
        <p>
          Da det er globalt tilgængeligt i browserens konsol, kan du blot udskrive alle værdier til konsollen
          (forudsat at standardnavnekonventioner anvendes):
        </p>
        <p>
          <b>Websites med GTM</b>
        </p>
        <p>
          <code>Object.assign(...dataLayer)</code>
        </p>
        <p>
          <b>Websites med Adobe Launch eller DTM</b>
        </p>
        <p>
          <code>digitalData</code>
        </p>
        <p>
          <b>Tealium</b>
        </p>
        <p>
          <code>utag.data</code> eller <code>utag_data</code>
        </p>
        <p>
          Google Tag Manager har endda sin egen <strong>Debugger Mode</strong>. Du kan aktivere den fra GTM
          interfacet ved at klikke på <b>preview</b> i øverste højre hjørne.
        </p>
        <ImgScreenshot
          src="data-layer/gtm-activate-debugger-mode.png"
          alt="Skærmbillede af Google Tag Manager, der viser knappen til aktivering af debug-tilstand"
          caption={`Du kan aktivere GTM's debug-tilstand ved at klikke på preview-knappen. Hvis du derefter besøger din hjemmeside med den installerede GTM-container, vil debug-vinduet poppe op i bunden af browseren.`}
        />{" "}
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode.png"
          alt="skærmbillede af GTM debug-vinduet"
          caption="Du kan inspicere alle data layer-variabler og se deres aktuelle værdier, mens du interagerer med hjemmesiden. Derudover kan du overse rækkefølgen af hændelser, der kommer ind. Klik på hver hændelse for at se, hvilke ændringer hændelsen forårsagede."
        />
        <p>
          Hvis du ikke har adgang til tag management containeren, men alligevel vil fejlfinde den, kan du bruge en{" "}
          <b>chrome-udvidelse</b>.
        </p>
        <H as="h4">Data Layer Chrome-udvidelser</H>
        <p>
          Der er en række potentielle udvidelser til fejlfinding derude. Jeg foretrækker dem, der understøtter de fleste
          udbydere, så jeg ikke behøver at skifte mellem udvidelser, når jeg fejlfinder en anden hjemmeside.
        </p>
        <p>Følgende chrome-udvidelser er i øjeblikket mine favoritter til fejlfinding:</p>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
            >
              <b>Trackie</b>
            </a>{" "}
            - Udvidelse baseret på{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
            >
              Data Slayer
            </a>{" "}
            og open source. Den har en ret god ydeevne og understøtter GTM, DTM, Tealium{" "}
            <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
              og mange flere
            </a>
            .
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
            >
              <b>Omnibug</b>
            </a>{" "}
            - En anden all-rounder med support til Adobe Analytics (DTM & Launch) samt Matomo, GTM, Tealium{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
            >
              og flere
            </a>
            .
          </li>
        </ul>
        <H as="h4">Chrome-udvidelser til fejlfinding af Google Analytics og GTM</H>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
              <b>GTM/GA Debug</b>
            </a>{" "}
            - efter aktivering af udvidelsen vil der være en ny fane tilgængelig i Chrome DevTools, når du åbner dem
            (F12 på Windows og CTRL+SHIFT+i på Mac). Alle relevante data vises i en grafisk brugergrænseflade, og den opdateres,
            mens du browser en hjemmeside.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
              <b>Adswerve - dataLayer Inspector+</b>
            </a>{" "}
            - logger alle relevante datapunkter ind i browserens konsol. Aktivér "preserve log" i konsolindstillingerne
            for at beholde logs på tværs af sidenavigation.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
              <b>Data Slayer</b>
            </a>{" "}
            - absolut udvidelsen med det fedeste navn og logo og min tidligere favorit. Det er open source
            med et simpelt layout og fungerer også med Adobe DTM. <br />
            <u>
              Fjern markeringen af “use three-column layout where available”, “show GA Classic tags” og “show Floodlight tags” i
              udvidelsesindstillingerne,
            </u>{" "}
            ellers bliver logs lidt rodede.
          </li>
        </ul>
        <H as="h4">Chrome-udvidelser til fejlfinding af Adobe Analytics, Launch og DTM</H>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
            >
              <b>Launch & DTM Switch</b>
            </a>{" "}
            - giver dig mulighed for at indlæse staging- eller produktionsbiblioteket i tag management systemet og kan aktivere
            debug-tilstand.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
            >
              <b>Debugger for Adobe Analytics</b>
            </a>{" "}
            - aktiverer debug-tilstand. Alternativt kan du også skrive <code>_satellite.setDebug(true)</code> i
            konsollen.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
            >
              <b>Adobe Experience Cloud Debugger</b>
            </a>{" "}
            - metaudvidelse til at fejlfinde alle Adobe-produkter.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
            >
              <b>Adobe Experience Platform Debugger</b>
            </a>{" "}
            - efterfølger til experience cloud debugger, der tilbyder et bedre overblik (i øjeblikket stadig i beta).{" "}
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
            >
              <b>Tealium Data Layer Debugger</b>
            </a>{" "}
            - simple tabeller over alle aktuelle værdier.
          </li>
        </ul>
        <H as="h2">E-Commerce Data Layer</H>
        <p>
          Data layers for e-handel er mere omfattende, og deres struktur er mere kompleks. De skal indeholde flere
          data og håndtere flere hændelser.
        </p>
        <p>Derfor tager planlægningen og implementeringen af en e-handels hjemmeside betydeligt længere tid.</p>
        <p>
          E-handelsrapporten i Google Analytics viser for eksempel ingen data, hvis implementeringen ikke følger deres{" "}
          <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=da">
            e-handels data layer dokumentation
          </a>
          .
        </p>
        <p>
          Store e-handelsbutikker kræver dog endnu mere avancerede tracking-opsætninger. De implementerer en{" "}
          <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
            data layer for enhanced e-commerce
          </a>
          , som muliggør endnu mere funktionalitet i Google Analytics.
        </p>
        <p>
          Du skal følge implementeringsretningslinjerne for Google Analytics nøje for at få e-handelsrapporterne til at fungere. Det gælder både data layer-strukturen og variabelnavne.
        </p>
        <p>
          Hvis du vælger en anden analyseplatform til e-handel, er du fri til at planlægge strukturen, som du vil.
        </p>
        <H as="h2">Hvornår er et data layer ikke nødvendigt?</H>
        <p>
          Som med alt andet er der også tilfælde, hvor den ekstra indsats ved implementering af et data layer ikke er berettiget.
        </p>
        <p>
          I de ovenstående eksempler kiggede vi på tilfælde, hvor vi hentede data fra forskellige datakilder (Frontend, Backend, API) og løste problemer, der opstod ved at arbejde med en række datakilder.
        </p>
        <p>
          Mange hjemmesider (såkaldte brochure-websites) har dog ikke engang en login-funktionalitet, og de har heller ikke en database.
        </p>
        <p>
          En anden vigtig faktor er, hvor ofte ændringer implementeres på hjemmesiden. Mange sider gennemgår sjældent deres indhold eller tilføjer funktionalitet regelmæssigt. Jeg ser endda virksomheder køre simple brochure-websites med omkring 50 undersider og en kontaktformular som den hårdeste konvertering.
        </p>
        <p>
          Da sådanne sider sandsynligvis kun kræver data fra frontend for at udføre deres dataanalyse, kunne de klare sig med en simpel analyseopsætning uden noget data layer. Det ville ikke gøre indsamlingen meget mere robust eller sikker, så dens fordele mindskes. Under sådanne omstændigheder retfærdiggør fordelene ikke den ekstra indsats ved implementeringen.
        </p>
        <p>
          Typiske eksempler på, hvornår et data layer ikke er nødvendigt, er brochure-websites eller indholdshjemmesider med et begrænset antal eller næsten ingen hårde konverteringer. Normalt er sådanne sideejere simpelthen interesserede i at kategorisere brugerengagement efter deres indholdssektioner eller nogle interne virksomhedsklassifikationer.
        </p>
        <p>
          Sådanne krav kan opnås med noget avanceret JavaScript og et gennemtænkt system til strukturering af indholdet.
        </p>
        <p>
          Så snart dataindsamling fra frontend regelmæssigt går i stykker og bestemt når en database skal involveres, anbefales et data layer.
        </p>
        <p>
          Alternative løsninger er ofte kun midlertidigt tilfredsstillende på grund af stigende analyseambitioner og regelmæssigt svigtende dataindsamling. Derudover er enhver specialløsning normalt svær at overdrage til et andet bureau.
        </p>
        <p>
          Et data layer har gode chancer for at overleve tidens test, fordi det allerede er et etableret koncept inden for webanalyse, så webbureauer har stigende erfaring med at implementere og vedligeholde et.
        </p>
        <H as="h2">Konklusion</H>
        <p>
          Et data layer er guldstandarden for professionelle analyseopsætninger. Det øger datakvaliteten og forbedrer dermed dataanalysen som helhed, samtidig med at det opfylder sikkerhedskrav.
        </p>
        <p>
          For ambitiøse hjemmesideejere, der ønsker at starte med seriøs dataanalyse, er det den nemmeste og mest robuste løsning.
        </p>
        <p>
          Hvis du har valget, implementer en array-baseret struktur, da du har færre afhængigheder og kan bruge den på alle slags hjemmesider.
        </p>
        <p>
          Indholdshjemmesider er dog så begrænsede i funktionalitet og har normalt kun et begrænset antal hårde konverteringer, så et data layer kan potentielt undværes. Dette gælder især, hvis alle nødvendige data er tilgængelige på siderne eller kan gøres tilgængelige gennem nogle omveje.
        </p>
        <p>
          Hvis du vil implementere et selv, er det sandsynligvis nemmest at gøre det med en WordPress-hjemmeside. Enhver avanceret krav er dog sandsynligvis ikke værd at bruge tid på og risikere et utilfredsstillende resultat.
          <br />
          Derfor er implementering med hjælp fra en analytics konsulent normalt vejen at gå, da det sparer tid og undgår unødvendige risici.
        </p>
        <p>
          Jeg anbefaler, at du installerer en af de nævnte chrome-udvidelser for at inspicere data layers på nogle større hjemmesider derude. Det er normalt en stor inspiration og giver nogle interessante KPI'er, der potentielt kan integreres i dine egne analyseopsætninger 😉.
        </p>
        <H as="h2">Data Layer dokumentation fra forskellige TMS-udbydere</H>
        <ul>
          <li>
            <b>Google Tag Manager</b>:{" "}
            <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
              Initiering og ændring af data
            </a>
          </li>

          <li>
            <b>Adobe Launch</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.adobe.com/content/help/da/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
            >
              Initiering
            </a>
          </li>

          <li>
            <b>Tealium iQ</b>:{" "}
            <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
              Initiering
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
            >
              ændring af data
            </a>
          </li>

          <li>
            <b>Matomo</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
            >
              Initiering
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
            >
              ændring af data
            </a>
          </li>

          <li>
            <b>Piwik Pro</b>:{" "}
            <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
              Initiering
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
            >
              ændring af data
            </a>
          </li>
        </ul>
        <H as="h2">FAQ</H>
        <FaqComponent data={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Hvad er et data layer eksempel?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Et data layer eksempel er givet i artiklen. Et JavaScript-objekt gemmer data fra en hjemmeside, database eller en ekstern kilde på en central, fleksibel og let tilgængelig måde. Et eksempel på kode til at starte et data layer for Google Tag Manager er: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"category page\", \"pageName\": \"sneaker overview\", \"language\": \"en-US\",}];"
              }
            },
            {
              "@type": "Question",
              "name": "Hvad er data layer variabler?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data layer variabler er nøgle-værdi par inden for data layer, der gemmer specifikke informationer. Disse variabler kan inkludere sideegenskaber, brugeradfærdsdata og mere, og fungerer som et centralt data repository til analyse og sporing."
              }
            },
            {
              "@type": "Question",
              "name": "Hvorfor bruge et data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Et data layer er essentielt for robust, fleksibel og sikker dataindsamling. Det centraliserer data fra forskellige kilder, hvilket gør det let tilgængeligt og konsistent på tværs af forskellige websider og brugerinteraktioner. Denne tilgang forbedrer datakvaliteten og pålideligheden, hvilket er afgørende for databaseret beslutningstagning."
              }
            },
            {
              "@type": "Question",
              "name": "Har jeg brug for et data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Selvom det ikke altid er nødvendigt, anbefales et data layer generelt til dem, der er seriøse med deres dataanalyseambitioner. Det giver datakvalitet, pålidelighed og langsigtede tidsbesparelser, der retfærdiggør de højere implementeringsomkostninger."
              }
            },
            {
              "@type": "Question",
              "name": "Hvad er fordelene ved et data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Fordelene ved et data layer inkluderer: Tilgængelighed af data uanset dets synlighed på siden. Robust dataindsamling. Reduktion af datatab ved asynkrone dataanmodninger. Sikker dataindsamling fra flere kilder."
              }
            },
            {
              "@type": "Question",
              "name": "Har alle hjemmesider et data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ikke alle hjemmesider har et data layer. Dets implementering afhænger af hjemmesidens kompleksitet og dybden af den krævede dataanalyse. Enkle hjemmesider har måske ikke et data layer, mens mere komplekse sider, især dem der fokuserer på databaseret beslutningstagning, sandsynligvis vil have det."
              }
            },
            {
              "@type": "Question",
              "name": "Hvordan får jeg adgang til data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data layer er globalt tilgængeligt i browserens konsol. For hjemmesider med Google Tag Manager kan du få adgang til det ved at bruge dataLayer eller Object.assign(...dataLayer). For Adobe Launch eller DTM kan du få adgang til det ved at bruge digitalData."
              }
            },
            {
              "@type": "Question",
              "name": "Hvordan skubber man til data layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "For at skubbe til data layer bruger du dataLayer.push()-metoden. For eksempel: window.dataLayer.push({ \"event\": \"course-booking\", \"startWeek\": \"24\" }); Denne metode bruges til at tilføje nye data eller ændringer til data layer. Event-nøglen kan bruges til at udløse en anden tag-eksekvering i tag management systemet."
              }
            }
          ]
        }
        } />
        <H as="h2">Yderligere ressourcer</H>
        <ul>
          <li>
            Simo Ahava om{" "}
            <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
              data layer i GTM
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
            >
              hvordan man håndterer data i det.
            </a>
          </li>
          <li>Læs min <Link to="/da/google-tag-manager-opsætning">Google Tag Manager tutorial</Link> og lær at sætte det op.</li>
          <li>
            Kevin Haags præsentation fra Measurecamp Berlin 2019 om{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
            >
              Event Driven Data Layer i Adobe Analytics
            </a>
          </li>
        </ul>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default DataLayer;